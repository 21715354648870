import { getProtoGroupList, assignGroup } from '@/api/product/protoGroupApi'
import { getGroupInfo } from '@/api/auth/distributorAssign'
import { getPictureGroupList } from '@/api/image/pictureGroupApi'
import { TYPE_IS_PROTO, TYPE_IS_PICTRUE, PICTURE_GROUP_ENABLE, PROTO_GROUP_ENABLE } from '@/utils/constant'

import { checkPermission } from '@/utils'
import { PICTURE_GROUP_ENABLE_DIC } from '@/utils/constant'

export const togglePictureGroupDialogTableOption = {
  search: false,
  topPage: false,
  menu: false,
  column: [
    {
      label: '',
      prop: 'radio',
      width: 50,
      slot: true
    },
    {
      label: '分组名称',
      prop: 'name'
    },
    {
      label: '分组图片数',
      prop: 'count'
    },
    {
      label: '状态',
      prop: 'enable',
      dicData: PICTURE_GROUP_ENABLE_DIC
    },
    {
      label: '创建时间',
      prop: 'createTime'
    }
  ]
}

export const togglePrototypeGroupDialogTableOption = {
  search: false,
  topPage: false,
  menu: false,
  column: [
    {
      label: '',
      prop: 'radio',
      width: 50,
      slot: true
    },
    {
      label: '分组名称',
      prop: 'name'
    },
    {
      label: '分组原型数',
      prop: 'count'
    },
    {
      label: '创建时间',
      prop: 'createTime'
    }
  ]
}

export const optionsList = {
  picture: {
    title: '选择图片分组',
    btnTxt: '更换图片分组',
    permisionList: {
      hasAdd: checkPermission(['externaladmin:user:resellerPage:picture:togglePictureGroup:add'])
    },
    tableOption: togglePictureGroupDialogTableOption,
    resetMergeData: {
      enable: PICTURE_GROUP_ENABLE,
      orderItems: [
        {
          asc: false,
          column: 'create_time'
        },
        {
          asc: false,
          column: 'id'
        }
      ]
    },
    getListFn: getPictureGroupList,
    async getGroupInfo() {
      const id = this.$route.query.id
      /* eslint-disable*/
      const [, { detail }] = await awaitWrap(
        getGroupInfo({
          id
        })
      )
      return (detail || {}).imageGroupId || null
      /* eslint-enable*/
    },
    addSubmitFuncApi(groupId) {
      const data = this.$route.query.id
      return assignGroup({
        groupId,
        type: TYPE_IS_PICTRUE,
        userId: data
      })
    }
  },

  default: {
    title: '选择原型分组',
    btnTxt: '更换原型分组',
    permisionList: {
      hasAdd: checkPermission(['externaladmin:user:resellerPage:prototype:toggleProtoGroup:add'])
    },
    tableOption: togglePrototypeGroupDialogTableOption,
    resetMergeData: {
      enable: PROTO_GROUP_ENABLE,
      orderItems: [
        {
          asc: false,
          column: 'create_time'
        },
        {
          asc: false,
          column: 'id'
        }
      ]
    },
    getListFn: getProtoGroupList,
    async getGroupInfo() {
      const id = this.$route.query.id
      /* eslint-disable*/
      const [, { detail }] = await awaitWrap(
        getGroupInfo({
          id
        })
      )
      return (detail || {}).prototypeGroupId || null
      /* eslint-enable*/
    },
    addSubmitFuncApi(groupId) {
      const data = [this.$route.query.id]
      return assignGroup({
        groupId,
        type: TYPE_IS_PROTO,
        userIdList: data
      })
    }
  }
}

export function getOption(type) {
  return optionsList[type]
}
